import { DocumentReference } from 'fhir/r4'

export type PackageNameMapType = {
  [id: string]: string
}

interface RequestParams {
  patientId: string
  patientName: string
  practitionerId: string
  practitionerName: string
  fileName: string | null
  serviceRequestIds: string[]
  packageNameMap: PackageNameMapType
}

type PractitionerData = {
  practitionerId: string | undefined
  practitionerName: string | undefined
}

export const defaultPagination = {
  page: 1,
  perPage: 10,
  total: 0
}

export const defaultTable = [
  [
    {
      header: 'Select',
      value: ''
    },
    {
      header: 'Test Name',
      value: ''
    },
    {
      header: 'Test Date',
      value: ''
    },
    {
      header: 'Action',
      value: ''
    }
  ]
]

export const createReferralRequestBody = (
  requestParams: RequestParams
): Partial<DocumentReference> => {
  const {
    patientId,
    patientName,
    practitionerId,
    practitionerName,
    fileName,
    serviceRequestIds,
    packageNameMap
  } = requestParams
  const currentDateTime = new Date().toISOString()
  const requestBody = {
    resourceType: 'DocumentReference',
    status: 'current',
    category: [],
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      display: patientName
    },
    type: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '34133-9',
          display: 'Referral note'
        }
      ],
      text: 'Referral Letter'
    },
    date: currentDateTime,
    author: [
      {
        id: practitionerId,
        reference: `Practitioner/${practitionerId}`,
        display: practitionerName
      }
    ],
    content: [
      {
        attachment: {
          contentType: 'application/pdf',
          url: fileName,
          title: 'Referral Letter'
        }
      }
    ],
    context: {
      related: serviceRequestIds.map((serviceRequestId) => {
        return {
          id: serviceRequestId,
          reference: `ServiceRequest/${serviceRequestId}`,
          display: packageNameMap[serviceRequestId]
        }
      })
    }
  }

  return requestBody as Partial<DocumentReference>
}

export const getFormattedTime = () => {
  const currentTime = new Date()
  return currentTime.toISOString().replace(/[-T:.Z]/g, '')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSuccessfulResponse = (response: any) => {
  return response && response.status >= 200 && response.status < 300
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPractitionerData = (loggedInUser: any): PractitionerData => {
  const practitionerId: string | undefined = loggedInUser?.entry?.[0]?.resource?.id
  const practitionerGivenName: string[] | undefined =
    loggedInUser?.entry?.[0]?.resource?.name[0]?.given
  const practitionerName: string | undefined = practitionerGivenName?.length
    ? practitionerGivenName
        .map((namePart: string) => namePart.replace(/,/g, ''))
        .join(' ')
        .trim()
    : undefined

  return {
    practitionerId,
    practitionerName
  }
}
