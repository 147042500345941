import React, { ReactElement } from 'react'
import { SWRConfig } from 'swr'
import { useAuth } from '@contexts/AuthProvider'
import { Source, useAxios } from '@contexts/AxiosProvider'

const SWRConfigWrapper = ({ children }: { children: ReactElement }) => {
  const { getUserFirebaseToken } = useAuth()
  const { axiosInstanceFhir, axiosInstanceAccountService, axiosInstanceConfigService, axiosInstanceOrder } = useAxios()

  return (
    <SWRConfig
      value={{
        fetcher: (resource: string, source: Source) => {
          let axiosInstance
          switch (source) {
            case Source.ACCOUNT:
              axiosInstance = axiosInstanceAccountService
              break
            case Source.CONFIG:
              axiosInstance = axiosInstanceConfigService
              break
            case Source.FHIR:
              axiosInstance = axiosInstanceFhir
              break
            case Source.ORDER:
              axiosInstance = axiosInstanceOrder
              break
            default:
              axiosInstance = axiosInstanceFhir
              break
          }
          return resource && axiosInstance.get(resource).then((res) => res?.data)
        },
        onError: (error) => {
          if (error.response && error.response.status === 403) {
            getUserFirebaseToken()
          } else {
            console.error('contexts/SWRConfigWrapper.tsx#SWR fetcher error - ', error)
          }
        }
      }}
    >
      {children}
    </SWRConfig>
  )
}

export { SWRConfigWrapper }
