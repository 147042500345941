import React, { ReactNode } from 'react'
import { Sidebar } from './Sidebar'
import { Content } from './Content'
import { Header } from './Header'
import { Grid, GridItem } from '@chakra-ui/react'
import PatientHeader from '@components/PatientHeader/PatientHeader'
import { PatientHeaderProvider } from '@contexts/PatientHeaderProvider'
import { useRouter } from 'next/router'

interface Props {
  children: ReactNode
}

const patientHeaderPaths = [
  '/patient/[id]',
  '/appointment/[id]',
  '/appointment/video/[id]',
  '/patient/review/[id]'
]

const Layout: React.FC<Props> = ({ children }) => {
  const router = useRouter()

  const mainWidth = 'calc(100vw - var(--chakra-space-4))'

  const hasPatientHeader = patientHeaderPaths.includes(router.pathname)

  return (
    <PatientHeaderProvider>
      <Sidebar />
      <Grid
        width="100%"
        height="100%"
        minHeight="100vh"
        m={0}
        p={0}
        backgroundColor="quredGray.600"
        templateAreas={`"header"
        "main"
        "footer"`}
        gridTemplateRows="var(--header-height) 1fr var(--footer-height)"
        gridTemplateColumns="1fr"
      >
        <GridItem area="header" width="100%">
          <Header />
          {hasPatientHeader && <PatientHeader />}
        </GridItem>
        <GridItem
          area="main"
          width="100%"
          maxWidth={mainWidth}
          pr={8}
          pl={6}
          mt={hasPatientHeader ? 24 : 6}
        >
          <Content>{children}</Content>
        </GridItem>
        <GridItem area="footer"></GridItem>
      </Grid>
    </PatientHeaderProvider>
  )
}

export default Layout
