import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useModal } from '@contexts/ModalProvider'
import {
  Button,
  Flex,
  useToast,
  Box,
  Checkbox,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react'
import { Source, useAxios } from '@contexts/AxiosProvider'
import { useAuth } from '@contexts/AuthProvider'
import axios from 'axios'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import { BundleEntry, DocumentReference } from 'fhir/r4'
import { CustomServiceRequest } from 'types/fhir'
import { GenericTable } from '@components/Table'
import { CommonIcon } from '@components/Icon/CommonIcon'
import { NoData } from '@components/Misc/'
import { Category } from 'types/misc'
import {
  createReferralRequestBody,
  getFormattedTime,
  isSuccessfulResponse,
  defaultPagination,
  defaultTable,
  getPractitionerData,
  PackageNameMapType
} from './utils'
import * as Sentry from '@sentry/nextjs'

export interface CreateReferralProps {
  patientID: string
  patientName: string
  serviceRequestIds?: string[]
  document?: DocumentReference
  refreshDocumentsData?: () => void
  setIsReferButtonClicked?: (value: boolean) => void
}

const Item = ({ icon, value }: { icon: string; value: string }) => {
  return (
    <Flex gap={2} alignItems="center">
      <CommonIcon name={icon} size={22} color="gray.100" />
      <Text fontWeight="bold" color="blackAlpha">
        {value}
      </Text>
    </Flex>
  )
}

const CreateReferral: React.FC<CreateReferralProps> = ({
  patientID,
  patientName,
  serviceRequestIds,
  document,
  refreshDocumentsData,
  setIsReferButtonClicked
}) => {
  const toast = useToast()
  const { user } = useAuth()
  const router = useRouter()
  const { axiosInstanceAccountService, axiosInstanceFhir } = useAxios()
  const { closeModal } = useModal()
  const [pagination, setPagination] = useState(defaultPagination)
  const [selectedRows, setSelectedRows] = useState<string[]>(serviceRequestIds || [])
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [localFileName, setLocalFileName] = useState<string | null>(null)
  const [isUploadLoading, setUploadLoading] = useState(false)
  const [isReferralLoading, setReferralLoading] = useState(false)
  const shouldFetch = user && user.uid

  const preUploadedFileName = document?.content?.[0]?.attachment?.url
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(preUploadedFileName)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false)
  }

  const handleConfirmOverwrite = () => {
    setConfirmationModalOpen(false)
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const { data: serviceRequestData } = useSWR([
    `/ServiceRequest?status=completed&subject=${patientID}`,
    Source.FHIR
  ]) as {
    data: { entry: BundleEntry<CustomServiceRequest>[]; total: number }
  }

  const { data: practitionerRecord } = useSWR(
    shouldFetch && [`/Practitioner?identifier=${user.uid}`, Source.FHIR]
  )
  const packageNameMap: PackageNameMapType = {}
  if (serviceRequestData?.entry) {
    serviceRequestData.entry.map((serviceRequest) => {
      const testName = serviceRequest.resource?.package?.name
      packageNameMap[serviceRequest.resource?.id as string] = testName as string
    })
  }

  const handleNext = useCallback(() => {
    const { page } = pagination
    setPagination({
      ...pagination,
      page: page + 1
    })
  }, [pagination])

  const handlePrevious = useCallback(() => {
    const { page } = pagination
    setPagination({
      ...pagination,
      page: page - 1
    })
  }, [pagination])

  const handlePerPageChange = useCallback((value: number) => {
    setPagination({ ...defaultPagination, perPage: value })
  }, [])

  useEffect(() => {
    if (serviceRequestData) {
      setPagination({
        ...pagination,
        total: serviceRequestData.total
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequestData])

  const handleAddPDFClick = () => {
    if (document?.id) {
      setConfirmationModalOpen(true)
    } else if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const createPatientReferral = async () => {
    if (!uploadedFileName) {
      showErrorToast('No File Attached', 'Please Attach File')
      return
    }
    const { practitionerId, practitionerName } = getPractitionerData(practitionerRecord)
    if (!practitionerId) {
      showErrorToast('Only Practitioner Can Refer')
      return
    }
    setReferralLoading(true)
    const body = createReferralRequestBody({
      patientId: patientID,
      patientName,
      practitionerId: practitionerId as string,
      practitionerName: practitionerName as string,
      fileName: uploadedFileName,
      serviceRequestIds: selectedRows,
      packageNameMap
    })
    try {
      document?.id
        ? await axiosInstanceFhir.put(`/DocumentReference/${document.id}`, body)
        : await axiosInstanceFhir.post('/DocumentReference', body)
      showSuccessToast('Referral Sent Successfully')
      Sentry.captureMessage(
        `Referral Document Sent Successfully - Document Name: ${uploadedFileName} (ID: ${document?.id}) - LoggedIn User ID: ${user?.uid}`
      )
      closeModal()
    } catch (error) {
      console.error('PatientHeader/CreateReferral.tsx#uploadDocument', error)
      Sentry.captureException(error, {
        extra: {
          loggedInUser: user?.uid,
          category: Category.ReferralLetter,
          fileName: uploadedFileName,
          referenceId: document?.id
        }
      })
      showErrorToast('Error Sending Referral')
    } finally {
      refreshDocumentsData && refreshDocumentsData()
      setIsReferButtonClicked && setIsReferButtonClicked(true)
      setReferralLoading(false)
    }
  }

  const handleResultsClick = useMemo(
    () => (serviceRequestId: string) => {
      router.push(`/patient/results/${serviceRequestId}?patientId=${patientID}`)
      closeModal()
    },
    [patientID, router, closeModal]
  )

  const handleFileSelected = async (
    event: React.ChangeEvent<HTMLInputElement>,
    patientID: string
  ) => {
    setUploadLoading(true)
    try {
      const selectedFile = event.target.files?.[0]
      if (!selectedFile) return

      if (selectedFile.type !== 'application/pdf') {
        showErrorToast('Invalid File Type', 'File Type Should be PDF')
        return
      }

      const formattedTime = getFormattedTime()
      const uniqueFileName = `${patientID}/${formattedTime}.pdf`

      setUploadedFileName(uniqueFileName)

      const uploadLink = await getSignedUploadUrl(uniqueFileName)

      if (isSuccessfulResponse(uploadLink)) {
        const signedUploadUrl = uploadLink.data.url
        await uploadFile(signedUploadUrl, selectedFile)
        showSuccessToast('File Uploaded')
        setLocalFileName(selectedFile.name)
      }
    } catch (error) {
      showErrorToast('Error Uploading')
    } finally {
      resetFileInput()
      setUploadLoading(false)
    }
  }

  const getSignedUploadUrl = async (uniqueFileName: string) => {
    return axiosInstanceAccountService.post('cloud-storage/signed-url-for-upload', {
      category: Category.ReferralLetter,
      fileName: uniqueFileName
    })
  }

  const uploadFile = async (signedUploadUrl: string, selectedFile: File) => {
    await axios.put(signedUploadUrl, selectedFile, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
  }

  const showErrorToast = (title: string, description?: string) => {
    toast({
      title,
      description,
      status: 'error',
      duration: 3000,
      isClosable: true
    })
  }

  const showSuccessToast = (title: string) => {
    toast({
      title,
      status: 'success',
      duration: 3000,
      isClosable: true
    })
  }

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const tableData = useMemo(() => {
    const handleSelectRow = (rowId: string) => {
      if (selectedRows?.includes(rowId)) {
        setSelectedRows(selectedRows.filter((id) => id !== rowId))
      } else {
        setSelectedRows([...selectedRows, rowId])
      }
    }
    return (
      serviceRequestData?.entry &&
      serviceRequestData?.entry.map((serviceRequest) => {
        const resource = serviceRequest.resource

        const testName = resource?.package?.name
        const testDate = resource?.occurrenceDateTime
        return [
          {
            header: 'Select',
            value: (
              <Checkbox
                isChecked={selectedRows?.includes(resource?.id as string)}
                onChange={() => handleSelectRow(resource?.id as string)}
              />
            )
          },
          {
            header: 'Test Name',
            value: testName
          },
          {
            header: 'Test Date',
            value: new Date(testDate as string).toLocaleDateString()
          },
          {
            header: 'Action',
            isAction: true,
            value: (
              <Button onClick={() => handleResultsClick(resource?.id as string)} size="sm">
                View Result
              </Button>
            )
          }
        ]
      })
    )
  }, [serviceRequestData?.entry, selectedRows, handleResultsClick])

  return (
    <Flex flexDirection="column" width="100%">
      <Flex mb={6} flexDirection="row" justifyContent="space-between" alignItems="center">
        {localFileName && (
          <Box ml="auto">
            <Box display="flex" alignItems="center">
              <Item icon="pdfFile" value={`Uploaded:  ${localFileName}`}></Item>
              <Box w={6} h={6} display="flex" alignItems="center" justifyContent="center" ml={1}>
                <CommonIcon name={'successTick'} size={20} color="green" />
              </Box>
            </Box>
          </Box>
        )}
        <Button
          isLoading={isUploadLoading}
          isDisabled={isReferralLoading}
          colorScheme={'red'}
          size="md"
          ml="auto"
          onClick={handleAddPDFClick}
        >
          {document?.id ? 'Update PDF' : 'Add PDF'}
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={(event) => handleFileSelected(event, patientID)}
        />
      </Flex>
      <Item icon="review" value={'Completed Test Results'} />
      {tableData ? (
        <Flex w="100%" h="100%" backgroundColor="white" borderRadius="xl">
          <GenericTable
            tableData={tableData}
            perPage={pagination.perPage}
            total={pagination.total}
            page={pagination.page}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handlePerPageChange={handlePerPageChange}
            size="sm"
          />
        </Flex>
      ) : (
        <>
          <Flex w="100%" h="100%" backgroundColor="white" borderRadius="xl">
            <GenericTable
              tableData={defaultTable}
              perPage={pagination.perPage}
              total={pagination.total}
              page={pagination.page}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handlePerPageChange={handlePerPageChange}
              size="sm"
              disablePagination={true}
            />
          </Flex>
          <NoData />
        </>
      )}
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <Button
          isLoading={isReferralLoading}
          isDisabled={isUploadLoading}
          onClick={createPatientReferral}
          colorScheme={'red'}
          size="md"
          ml="auto"
        >
          Send Referral
        </Button>
      </Flex>
      <Modal isOpen={isConfirmationModalOpen} onClose={closeConfirmationModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box justifyContent="center" flex={1} display="flex" alignItems="center">
              <CommonIcon name={'warning'} size={60} color="#F5A623" />
            </Box>
            <Text textAlign="center" mt={4} fontSize="lg">
              You are requesting to update the referral document. By confirming, the previous
              document will be overwritten.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button colorScheme="green" mr={2} onClick={handleConfirmOverwrite}>
              Yes, confirm
            </Button>
            <Button colorScheme="red" onClick={closeConfirmationModal}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default CreateReferral
