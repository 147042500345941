// Terminology for these codes lives in here http://terminology.hl7.org/CodeSystem/v2-0203
export const NHS_FHIR_CODE = 'NH'
export const NHS_FHIR_SYSTEM = 'https://fhir.nhs.uk/Id/nhs-number'
export const NHS_FHIR_DISPLAY = 'National Health Plan Identifier'
export const PMI_ID_FHIR_CODE = 'MB'
export const PMI_ID_DISPLAY = 'Insurance Identifier'
export const EMPLOYEE_ID_CODE = 'PI'
export const EMPLOYEE_ID_DISPLAY_NAME = 'Employee Number'
export const TAGS_FHIR_URL = 'http://hl7.org/fhir/search-param-type'
export const GENERIC_CODE_SYSTEM_URL = 'http://terminology.hl7.org/CodeSystem/v2-0203'
export const QUICKBLOX_EVENT_ID_SYSTEM_KEY = 'quickblox_event_id'
export const EXTERNAL_TYPE_SYSTEM_KEY = 'external_type'


