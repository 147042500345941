import React, { useState, FC } from 'react'
import { Button, useToast, Flex, Stack, Text } from '@chakra-ui/react'
import { Tag } from '@components/Misc'
import uniqueId from 'lodash.uniqueid'
import { useModal } from '@contexts/ModalProvider'
import { Source, useAxios } from '@contexts/AxiosProvider'
import useSWR from 'swr'
import { LookupCategory, Lookup } from 'types/index'

interface Props {
  firebaseUserId: string
  clinicalTags: string[]
  refreshPatientData: () => void
}

const TagPatientModal: FC<Props> = ({ firebaseUserId, clinicalTags, refreshPatientData }) => {
  const { setLoading, closeModal } = useModal()
  const [preSelectedTags, setPreSelectedTags] = useState<string[]>(clinicalTags || [])
  const toast = useToast()
  const { axiosInstanceAccountService } = useAxios()
  const { data: tagsResponse }: { data?: LookupCategory } = useSWR([
    `/reference-data/ClinicalTag`,
    Source.ACCOUNT
  ])

  const dashboardTags = tagsResponse?.lookups.map((i: Lookup) => i.name)
  const handleAction = async () => {
    setLoading(true)

    try {
      await axiosInstanceAccountService.patch(`/users/${firebaseUserId}`, {
        clinicalTags: preSelectedTags
      })
      refreshPatientData()
      toast({
        title: 'Tags added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
      closeModal()
    } catch (error) {
      console.error('components/TagPatientModal/TagPatientModal.tsx#handleAction', error)
      toast({
        title: 'Error tagging patient',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Text fontSize="md">Select Tags</Text>
      <Flex flexWrap="wrap" gap={2} mb={6} mt={6}>
        {dashboardTags?.map((currentTag: string) => {
          const isSelected = preSelectedTags?.includes(currentTag)
          return (
            <Tag
              key={uniqueId(currentTag)}
              size="sm"
              color={isSelected ? 'white' : 'quredGray.450'}
              borderRadius="full"
              borderColor="var(--chakra-colors-quredGray-450)"
              backgroundColor={isSelected ? 'quredGray.450' : 'white'}
              variant="outline"
              cursor="pointer"
              onClick={() => {
                if (isSelected) {
                  const newTags = preSelectedTags.filter(
                    (selectedTags) => selectedTags !== currentTag
                  )
                  setPreSelectedTags(newTags)
                } else {
                  const newTags = [...preSelectedTags, currentTag]
                  setPreSelectedTags(newTags)
                }
              }}
            >
              {currentTag}
            </Tag>
          )
        })}
      </Flex>
      <Stack spacing={4}>
        <Button size="md" onClick={handleAction} disabled={preSelectedTags?.length === 0}>
          Apply
        </Button>
        <Button
          size="md"
          variant="secondary"
          onClick={() => {
            setPreSelectedTags([])
          }}
        >
          Clear All Tags
        </Button>
      </Stack>
    </Flex>
  )
}

export default TagPatientModal
