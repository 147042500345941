import React from 'react'
import { Stack, Heading, Flex, Text, IconButton, useToast } from '@chakra-ui/react'
import { CommonIcon } from '@components/Icon/CommonIcon'
import { copyToClipboard } from '@utils/mics'

interface PatientCardProps {
  id: string
  name: string
  age: number
  gender: string
  birthDate: string
  email: string
  mobile: string
  city: string
}

const Item = ({ icon, value }: { icon: string; value: string }) => {
  return (
    <Flex gap={2} alignItems="center">
      <CommonIcon name={icon} size={22} color="blue" />
      <Text>{value}</Text>
    </Flex>
  )
}

const PatientCard = ({
  id,
  name,
  age,
  birthDate,
  gender,
  city,
  mobile,
  email
}: PatientCardProps) => {
  const toast = useToast()
  const goBack = () => {
    window.history.back()
  }

  return (
    <Flex>
      <Stack gap={3}>
        <Flex alignItems="center" gap={2}>
          <IconButton
            variant="ghost"
            color="black"
            aria-label="go-back"
            colorScheme="blackAlpha"
            icon={<CommonIcon name="back" size={24} />}
            size="sm"
            onClick={goBack}
          />
          <Heading size="md">{name}</Heading>
        </Flex>
        <Flex gap={1}>
          <Item icon="hash" value={id} />
          <IconButton
            aria-label="copy-to-clipboard"
            icon={<CommonIcon name="clipboard" size={24} />}
            size="sm"
            variant="ghost"
            onClick={() => {
              copyToClipboard(id)
              toast({
                title: 'Copied to clipboard',
                status: 'success',
                duration: 1500
              })
            }}
          />
        </Flex>
        <Flex gap={4}>
          <Item icon="user" value={age + ' y.o'} />
          <Item icon="calendar" value={birthDate} />
          <Item icon="medical" value={gender} />
        </Flex>
        <Flex gap={4}>
          <Item icon="email" value={email} />
          <Item icon="mobile" value={mobile} />
        </Flex>
        <Item icon="home" value={city} />
      </Stack>
    </Flex>
  )
}

export default PatientCard
