import React, { FC, ReactNode } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text
} from '@chakra-ui/react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { AiOutlineCaretDown } from 'react-icons/ai'
import uniqueId from 'lodash.uniqueid'
import { NoData } from '@components/Misc/'
import { TableHeaderEnum, ResultsColorMap } from 'types/review'
interface TableData {
  header: string
  value: string | number | ReactNode
  isAction?: boolean
  hide?: boolean
}

export interface TableProps {
  tableData: TableData[][]
  total?: number
  perPage?: number
  page?: number
  handleNext?: () => void
  handlePrevious?: () => void
  handlePerPageChange?: (value: number) => void
  handleRowClick?: (id: string) => void
  size?: 'sm' | 'md' | 'lg'
  disablePagination?: boolean
}

const StyledThCell = ({
  children,
  height,
  isAction,
  color = 'black'
}: {
  children: ReactNode
  height?: string
  isAction: boolean
  color?: string
}) =>
  !isAction ? (
    <Th color={color} height={height}>
      {children}
    </Th>
  ) : (
    <Th color={color} w="32px">
      {children}
    </Th>
  )

const StyledTdCell = ({
  children,
  isAction,
  status
}: {
  children: ReactNode
  isAction: boolean
  status?: string
}) => {
  const getTextColor = (status: string) => {
    return { color: status ? ResultsColorMap[status] : ResultsColorMap['inherit'] }
  }

  return !isAction ? (
    <Td>
      <span style={getTextColor(status as string)}>{children}</span>
    </Td>
  ) : (
    <Td display="flex" pl={0} pr={0} w="100%" h="100%" alignItems="center">
      {children}
    </Td>
  )
}

const PaginationTextItem = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={700} color="gray.900" fontSize="sm">
      {children}
    </Text>
  )
}

const GenericTable: FC<TableProps> = ({
  tableData,
  total,
  page,
  perPage,
  handlePrevious,
  handleNext,
  handlePerPageChange,
  handleRowClick,
  size,
  disablePagination
}) => {
  if (!tableData || tableData.length === 0) {
    return <NoData />
  }

  const headers = tableData[0].filter(
    ({ header, hide, isAction }) =>
      !hide && {
        header,
        isAction
      }
  )

  return (
    <TableContainer m={6} position="relative" w="100%">
      <Table size={size} colorScheme="quredGray" variant="striped">
        <Thead>
          <Tr>
            {headers.map(({ header, isAction }) => (
              <StyledThCell key={uniqueId(header)} isAction={Boolean(isAction)}>
                {header}
              </StyledThCell>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((row) => (
            <Tr
              key={uniqueId()}
              onClick={() => {
                const id = row.find(({ header }) => header === 'ID')?.value as string
                if (id && handleRowClick) {
                  handleRowClick(id)
                }
              }}
              cursor={handleRowClick ? 'pointer' : 'default'}
            >
              {row.map(({ header, value, isAction, hide }) => {
                if (
                  [TableHeaderEnum.SampleStatus, TableHeaderEnum.ReportStatus].includes(
                    header as TableHeaderEnum
                  )
                ) {
                  return (
                    !hide && (
                      <StyledTdCell
                        isAction={Boolean(isAction)}
                        key={uniqueId()}
                        status={value as string}
                      >
                        {value}
                      </StyledTdCell>
                    )
                  )
                } else {
                  return (
                    !hide && (
                      <StyledTdCell isAction={Boolean(isAction)} key={uniqueId()}>
                        {value}
                      </StyledTdCell>
                    )
                  )
                }
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {!disablePagination && (
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          position="sticky"
          bottom={0}
          left={0}
          mt={6}
          mb={4}
        >
          <PaginationTextItem>Total count: {total}</PaginationTextItem>
          <Flex>
            <Flex gap={1} alignItems="center">
              <IconButton
                isDisabled={page === 1}
                variant="ghost"
                aria-label="Previous page"
                icon={<FaArrowLeft />}
                onClick={() => {
                  if (handlePrevious) {
                    handlePrevious()
                  }
                }}
              />
              <PaginationTextItem>
                Page {page} of {total && perPage ? Math.ceil(total / perPage) : 0}
              </PaginationTextItem>
              {total && perPage && (
                <IconButton
                  isDisabled={page === Math.ceil(total / perPage)}
                  variant="ghost"
                  aria-label="Next page"
                  icon={<FaArrowRight />}
                  onClick={() => {
                    if (handleNext) {
                      handleNext()
                    }
                  }}
                />
              )}
            </Flex>
          </Flex>
          {handlePerPageChange && (
            <Flex mr={3} gap={2}>
              <PaginationTextItem>Per page: {perPage}</PaginationTextItem>
              <Menu>
                <MenuButton
                  variant="ghost"
                  size="xs"
                  as={IconButton}
                  icon={<AiOutlineCaretDown />}
                  ml="auto"
                />
                <MenuList minWidth="40px">
                  <MenuItem onClick={() => handlePerPageChange(10)}>10</MenuItem>
                  <MenuItem onClick={() => handlePerPageChange(15)}>15</MenuItem>
                  <MenuItem onClick={() => handlePerPageChange(20)}>20</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          )}
        </Flex>
      )}
    </TableContainer>
  )
}

export default GenericTable
