import { createContext, useContext, FC, ReactNode } from 'react'
import {
  NEXT_PUBLIC_FHIR_WRAPPER_URL,
  NEXT_PUBLIC_ACCOUNT_SERVICE_URL,
  NEXT_PUBLIC_CONFIG_SERVICE_URL,
  NEXT_PUBLIC_ORDER_SERVICE_URL,
  NEXT_PUBLIC_APPOINTMENT_SERVICE_URL
} from '@constants/appConstants'
import { useAuth } from './AuthProvider'
import axios, { AxiosInstance, AxiosError } from 'axios'
import * as Sentry from '@sentry/nextjs'

interface Props {
  children: ReactNode
}

export enum Source {
  FHIR = 'FHIR',
  ACCOUNT = 'ACCOUNT',
  CONFIG = 'CONFIG',
  ORDER = 'ORDER'
}

interface ContextValue {
  [key: string]: AxiosInstance
}

const AxiosContext = createContext({} as ContextValue)

const AxiosProvider: FC<Props> = ({ children }) => {
  const { getUserFirebaseToken, firebaseAccessToken } = useAuth()

  const errorHandling = async (error: AxiosError, source: Source) => {
    if (error.response && error.response.status === 403) {
      await getUserFirebaseToken()
    } else {
      Sentry.withScope(function (scope) {
        scope.setLevel('debug')
        scope.setTag('source', source)

        // The exception has the event level set by the scope (info).
        Sentry.captureException(error)
      })
    }
    return Promise.reject(error as AxiosError)
  }

  const axiosInstanceFhir = axios.create({
    baseURL: NEXT_PUBLIC_FHIR_WRAPPER_URL,
    headers: {
      Authorization: `Bearer ${firebaseAccessToken}`
    }
  })

  axiosInstanceFhir.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.FHIR)
    }
  )

  const axiosInstanceAccountService = axios.create({
    baseURL: NEXT_PUBLIC_ACCOUNT_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${firebaseAccessToken}`
    }
  })

  axiosInstanceAccountService.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      return errorHandling(error, Source.ACCOUNT)
    }
  )

  const axiosInstanceConfigService = axios.create({
    baseURL: NEXT_PUBLIC_CONFIG_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${firebaseAccessToken}`
    }
  })

  axiosInstanceConfigService.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      errorHandling(error, Source.CONFIG)
    }
  )

  const axiosInstanceOrder = axios.create({
    baseURL: NEXT_PUBLIC_ORDER_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${firebaseAccessToken}`
    }
  })

  axiosInstanceOrder.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      errorHandling(error, Source.ORDER)
    }
  )

  const axiosInstanceAppointment = axios.create({
    baseURL: NEXT_PUBLIC_APPOINTMENT_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${firebaseAccessToken}`
    }
  })

  axiosInstanceAppointment.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      errorHandling(error, Source.ORDER)
    }
  )

  return (
    <AxiosContext.Provider
      value={{
        axiosInstanceFhir,
        axiosInstanceAccountService,
        axiosInstanceConfigService,
        axiosInstanceOrder,
        axiosInstanceAppointment
      }}
    >
      {children}
    </AxiosContext.Provider>
  )
}

const useAxios = () => useContext(AxiosContext)

export { AxiosProvider, useAxios }
