import React, { ReactNode, FC } from 'react'
import { Heading, Flex, Text } from '@chakra-ui/react'
import { CommonIcon } from '@components/Icon/CommonIcon'

interface Props {
  children?: ReactNode
  title?: string
  subtitle?: string
  icon?: string
}

const SectionHeader: FC<Props> = ({ children, title, icon, subtitle }) => {
  return (
    <Flex justifyContent="space-between" position="relative" alignItems="center" mb={4} w="100%">
      <Flex alignItems="center" ml={1} gap={2}>
        {icon && <CommonIcon name={icon} size={24} />}
        {subtitle ? (
          <Flex direction="column">
            <Heading as="h2" size="md">
              {title}
            </Heading>
            <Text>{subtitle}</Text>
          </Flex>
        ) : (
          <Heading as="h2" size="lg" textTransform="capitalize">
            {title}
          </Heading>
        )}
      </Flex>
      {children}
    </Flex>
  )
}

export default SectionHeader
