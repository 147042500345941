import React, { ReactNode } from 'react'
import styles from './Content.module.css'

interface Props {
  children: ReactNode
}

export const Content: React.FC<Props> = ({ children }) => {
  return <main className={styles.wrapper}>{children}</main>
}
