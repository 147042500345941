import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Flex, IconButton, Text } from '@chakra-ui/react'
import Image from 'next/image'
import styles from './Header.module.css'
import { ProfileMenu } from '@components/Header'
import { useAppContext } from '@contexts/AppProvider'
import { ImpersonateMenu } from '@components/Misc'
import { useAuth } from '@contexts/AuthProvider'

export const Header: React.FC = () => {
  const { setSidebarOpen, sidebarOpen, isSuper, isSuperAdmin } = useAppContext()
  const { roles, activeRole } = useAuth()

  return (
    <header className={styles.wrapper}>
      <Flex alignItems="center" w="100%" pl={4} pt={2} pr={4} pb={2}>
        <Flex alignItems="center" mt={2}>
          <IconButton
            mr={5}
            p={0}
            aria-label="menu"
            icon={<GiHamburgerMenu size={20} />}
            variant="ghost"
            colorScheme="black"
            size="xs"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
          <Image src="/assets/qured-logo-full-blue.png" alt="Qured" width={70} height={28} />
        </Flex>
        <Flex ml="auto" alignItems="center" gap={3}>
          {(isSuper || isSuperAdmin) && <ImpersonateMenu />}
          <Text as="b">{activeRole ? activeRole.code : roles && roles[0]?.code}</Text>
          <ProfileMenu />
        </Flex>
      </Flex>
    </header>
  )
}
