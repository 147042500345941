import { ReactNode, ReactElement } from 'react'
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@components/theme'
import { AuthProvider } from '@contexts/AuthProvider'
import { PrivateRoute } from '@components/Layout'
import { SWRConfigWrapper } from '@contexts/SWRConfigWrapper'
import { AxiosProvider } from '@contexts/AxiosProvider'
import { AppProvider } from '@contexts/AppProvider'

import '@fontsource/inter'

import '@qured-team/fhir-react/build/style.css'
import '@qured-team/fhir-react/build/bootstrap-reboot.min.css'
import 'custom-css-vars.css'
import { ModalProvider } from '@contexts/ModalProvider'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)

  const protectedRoutes = [
    '/',
    '/patient',
    '/patient/[id]',
    '/patient/results/[id]',
    '/patient/review',
    '/patient/review/[id]',
    '/patient/result-pending-appointment',
    '/appointment',
    '/appointment/[id]',
    '/appointment/video/[id]',
    '/shift',
    '/management',
    '/appointment_v2'
  ]

  return (
    <AuthProvider>
      <AxiosProvider>
        <ChakraProvider theme={theme}>
          <SWRConfigWrapper>
            <AppProvider>
              <ModalProvider>
                <PrivateRoute protectedRoutes={protectedRoutes}>
                  {getLayout(<Component {...pageProps} />)}
                </PrivateRoute>
              </ModalProvider>
            </AppProvider>
          </SWRConfigWrapper>
        </ChakraProvider>
      </AxiosProvider>
    </AuthProvider>
  )
}
