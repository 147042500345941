import React, { FC } from 'react'
import { HiOutlineCreditCard, HiOutlineDeviceMobile } from 'react-icons/hi'
import { RxDashboard } from 'react-icons/rx'
import { FiUsers, FiKey, FiClipboard } from 'react-icons/fi'
import { FaUser, FaCheckCircle, FaApple } from 'react-icons/fa'
import { AiFillAndroid } from 'react-icons/ai'
import { GiVial } from 'react-icons/gi'
import { RiOrganizationChart, RiSettings4Fill } from 'react-icons/ri'
import { IoIosAlarm } from 'react-icons/io'
import { VscPreview } from 'react-icons/vsc'
import { BsCalendar3, BsPlus, BsQuestionDiamondFill } from 'react-icons/bs'
import { AiFillHome, AiOutlineNumber, AiOutlineSchedule, AiFillWarning } from 'react-icons/ai'
import { IoMdMedical, IoIosArrowBack } from 'react-icons/io'
import { MdEmail, MdOutlinePictureAsPdf } from 'react-icons/md'
import { BiMinus } from 'react-icons/bi'
import { RxCrossCircled } from 'react-icons/rx'

export interface CommonIconProps {
  name: string
  size: number
  color?: string
}

type IconType = FC<CommonIconProps>

const nameToIconMapping: { [key: string]: IconType } = {
  dashboard: RxDashboard,
  team: FiUsers,
  user: FaUser,
  key: FiKey,
  'credit-card': HiOutlineCreditCard,
  mobile: HiOutlineDeviceMobile,
  vial: GiVial,
  calendar: BsCalendar3,
  review: VscPreview,
  clock: IoIosAlarm,
  home: AiFillHome,
  medical: IoMdMedical,
  email: MdEmail,
  back: IoIosArrowBack,
  hash: AiOutlineNumber,
  clipboard: FiClipboard,
  shift: AiOutlineSchedule,
  plus: BsPlus,
  minus: BiMinus,
  organization: RiOrganizationChart,
  pdfFile: MdOutlinePictureAsPdf,
  successTick: FaCheckCircle,
  warning: AiFillWarning,
  failureCross: RxCrossCircled,
  ios: FaApple,
  android: AiFillAndroid,
  questionMark: BsQuestionDiamondFill,
  settings: RiSettings4Fill
}

export const CommonIcon: FC<CommonIconProps> = ({ name, size, color }) => {
  const IconComponent = nameToIconMapping[name] || null

  if (!IconComponent)
    throw new Error("This icon name doesn't exist. Please, add an icon or select an existing one.")

  return <IconComponent name={name} size={size} color={color} />
}
