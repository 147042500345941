import { Resource, Reference } from 'fhir/r4'
import get from 'lodash.get'

export const getName = (fhirData: Resource) => {
  const firstName = get(fhirData, 'name[0].given[0]') || ''
  const middleName = get(fhirData, 'name[0].given[1]') || ''
  const lastName = get(fhirData, 'name[0].family') || ''
  return {
    firstName,
    middleName,
    lastName
  }
}

export const extractId = ({ reference }: Reference) => {
  if (reference) return reference.split('/')[1]
}
