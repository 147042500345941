import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, MenuGroup, Avatar } from '@chakra-ui/react'
import { useAppContext } from '@contexts/AppProvider'
import { getName } from '@utils/fhir'
import { useAuth } from '@contexts/AuthProvider'

const ProfileMenu = () => {
  const { practitioner } = useAppContext()
  const { logout } = useAuth()
  const { firstName, lastName } = practitioner
    ? getName(practitioner)
    : { firstName: '', lastName: '' }

  return (
    <Menu>
      <MenuButton>
        <Avatar name={firstName + ' ' + lastName} size="sm" bg="quredOrange.500" color="white" />
      </MenuButton>
      <MenuList>
        <MenuGroup title="Profile">
          <MenuItem onClick={() => logout()}>Log Out</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default ProfileMenu
