import React, { FC, useCallback } from 'react'
import {
  Flex,
  Text,
  IconButton,
  Heading,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Skeleton,
  Stack,
  useToast
} from '@chakra-ui/react'
import { CommonIcon } from '@components/Icon/CommonIcon'
import { usePatientHeaderProvider } from '@contexts/PatientHeaderProvider'
import { useRouter } from 'next/router'
import { FaChevronDown } from 'react-icons/fa'
import { useModal } from '@contexts/ModalProvider'
import { Tag } from '@components/Misc'
import uniqueId from 'lodash.uniqueid'
import { copyToClipboard } from '@utils/mics'
import { useAxios } from '@contexts/AxiosProvider'

const Item = ({ icon, value }: { icon: string; value: string }) => {
  return (
    <Flex gap={2} alignItems="center">
      <CommonIcon name={icon} size={22} color="gray.100" />
      <Text color="blackAlpha">{value}</Text>
    </Flex>
  )
}

const PatientHeader: FC = () => {
  const toast = useToast()
  const { openModal, closeModal } = useModal()
  const { axiosInstanceAccountService } = useAxios()

  const router = useRouter()
  const {
    patientDetails,
    defaultActions,
    handleOrderTest,
    customActions,
    handleTagPatient,
    handleEditPatient,
    handleReferPatient,
    refreshPatientData
  } = usePatientHeaderProvider()

  const {
    id,
    firstName,
    middleName,
    lastName,
    birthDate,
    age,
    gender,
    phoneNumber,
    email,
    city,
    firebaseUserId,
    clinicalTags,
    organizationName
  } = patientDetails

  const handleRemoveTags = useCallback(
    (newTags: string[]) => {
      const removeTag = async () => {
        try {
          await axiosInstanceAccountService.patch(`/users/${firebaseUserId}`, {
            clinicalTags: newTags
          })
          refreshPatientData()
          toast({
            title: 'Tag removed successfully',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
        } catch (error) {
          console.error('components/TagPatientModal/TagPatientModal.tsx#removeTag', error)
          toast({
            title: 'Error removing tag from patient',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        } finally {
          closeModal()
        }
      }
      openModal({
        header: 'Are you sure you want to remove tags?',
        children: (
          <Button colorScheme="red" onClick={removeTag} w="100%">
            Yes, Remove
          </Button>
        )
      })
    },
    [openModal, closeModal, axiosInstanceAccountService, toast, firebaseUserId]
  )

  if (!patientDetails) {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    )
  }

  if (!id) {
    return (
      <Box bg="white">
        <Box ml={35} mt={2}>
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        </Box>
      </Box>
    )
  }

  const headerActionHandlerMap = new Map([
    ['Order Test', () => handleOrderTest(openModal, id)],
    ['Tag Patient', () => handleTagPatient(openModal, firebaseUserId, clinicalTags)],
    ['Edit Info', () => handleEditPatient(openModal, firebaseUserId)],
    ['Create Referral', () => handleReferPatient(openModal, id, firstName)]
  ])

  const name = `${firstName} ${middleName} ${lastName}`

  const goBack = () => {
    router.back()
  }

  const defaultMenuItems = defaultActions.map((actionName) => (
    <MenuItem key={actionName} onClick={headerActionHandlerMap.get(actionName)}>
      {actionName}
    </MenuItem>
  ))

  const customMenuItems = customActions.map(({ name, action }) => (
    <MenuItem key={name} onClick={action}>
      {name}
    </MenuItem>
  ))

  const headerActions = []

  if (customMenuItems.length) {
    headerActions.push(...customMenuItems)
  }

  if (defaultMenuItems.length) {
    if (headerActions.length) {
      headerActions.push(<MenuDivider key="divider" />)
    }
    headerActions.push(...defaultMenuItems)
  }

  return (
    <Flex direction="column" bg="white" height={79}>
      <Flex justifyContent="space-between" alignItems="center" ml={35} mt={2}>
        <Flex alignItems="center">
          <IconButton
            variant="ghost"
            color="black"
            aria-label="go-back"
            colorScheme="blackAlpha"
            icon={<CommonIcon name="back" size={24} />}
            size="lg"
            onClick={goBack}
          />
          <Flex direction="column">
            <Stack direction="row">
              <Heading mb={2} size="md">
                {name} |
              </Heading>
              <Text>
                <Item icon="organization" value={organizationName} />
              </Text>
              <Text> | Patient ID : {id}</Text>
              <IconButton
                aria-label="copy-to-clipboard"
                icon={<CommonIcon name="clipboard" size={24} />}
                size="xs"
                variant="ghost"
                onClick={() => {
                  copyToClipboard(id)
                  toast({
                    title: 'Copied to clipboard',
                    status: 'success',
                    duration: 1500
                  })
                }}
              />
            </Stack>
            <Flex direction="row" gap={4}>
              <Item
                icon="user"
                value={
                  gender
                    ? gender.charAt(0).toUpperCase() + gender.slice(1) + ', ' + age + ' y.o'
                    : ''
                }
              />
              <Item icon="calendar" value={birthDate} />
              <Item icon="email" value={email} />
              <Item icon="mobile" value={phoneNumber} />
              <Item icon="home" value={city} />
              <Flex alignItems="center" gap={1}>
                {clinicalTags?.map(
                  (tag: string) =>
                    tag && (
                      <Tag
                        key={uniqueId(tag)}
                        size="sm"
                        color="quredGray.450"
                        borderRadius="full"
                        borderColor="var(--chakra-colors-quredGray-300)"
                        backgroundColor="transparent"
                        variant="outline"
                        closeButton
                        onClick={() => {
                          const newTags = clinicalTags.filter(
                            (clinicalTags) => clinicalTags !== tag
                          )
                          handleRemoveTags(newTags)
                        }}
                      >
                        {tag}
                      </Tag>
                    )
                )}
                {clinicalTags && clinicalTags.length > 0 && (
                  <Button
                    variant="ghost"
                    size="xs"
                    colorScheme="gray"
                    onClick={() => {
                      handleRemoveTags([])
                    }}
                  >
                    Clear All
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Box width={110} mr={16}>
          <Menu>
            <MenuButton as={Button} rightIcon={<FaChevronDown />}>
              Actions
            </MenuButton>
            <MenuList>{headerActions}</MenuList>
          </Menu>
        </Box>
      </Flex>
      <Flex flexWrap="wrap" gap={1} ml="auto" mr={4}></Flex>
    </Flex>
  )
}

export default PatientHeader
