import { Flex, Heading } from "@chakra-ui/react";
import Image from "next/image";

function EmptyDataScreen() {
  return (
    <Flex
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mt={6}
    >
      <Heading as="h2" size="xl">
        No Data found
      </Heading>
      <Image width="100px" height="100px" src="/assets/empty.svg" alt="No data found" />
    </Flex>
  );
}

export default EmptyDataScreen;