import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  IconButton,
  MenuOptionGroup
} from '@chakra-ui/react'
import { useAuth } from '@contexts/AuthProvider'
import { BsGear } from 'react-icons/bs'
import { RoleType, Role, RoleStatus } from 'types/role'
import { useRouter } from 'next/router'

const ImpersonateMenu = () => {
  const [selected, setSelected] = React.useState<RoleType>()
  const { setRoles, roles, setActiveRole } = useAuth()
  const router = useRouter()

  const handleImpersonate = ({ role }: { role: Role }) => {
    if (!roles) {
      return
    }

    const newRoles = roles.filter(
      (iterator) =>
        iterator.code !== role.code &&
        iterator.code !== RoleType.GPPractitioner &&
        iterator.code !== RoleType.HealthAdvisor
    )
    setActiveRole(role)
    setRoles([...newRoles, role])
    setSelected(RoleType[role.code as keyof typeof RoleType])
    localStorage.setItem('roles', JSON.stringify(newRoles))
    const { pathname, query } = router
    delete query.shiftType
    router.replace({ pathname, query }, undefined, { shallow: true })
  }

  return (
    <Menu>
      <MenuButton
        variant="ghost"
        colorScheme="gray"
        as={IconButton}
        aria-label="Imperonate Menu"
        icon={<BsGear size={16} />}
      />
      <MenuList>
        <MenuOptionGroup value={selected} type="radio" title="Impersonate">
          <MenuItemOption
            value={RoleType.GPPractitioner}
            onClick={() =>
              handleImpersonate({
                role: {
                  code: RoleType.GPPractitioner,
                  status: RoleStatus.Active
                }
              })
            }
          >
            General Practiotioner
          </MenuItemOption>
          <MenuItemOption
            value={RoleType.HealthAdvisor}
            onClick={() =>
              handleImpersonate({
                role: {
                  code: RoleType.HealthAdvisor,
                  status: RoleStatus.Active
                }
              })
            }
          >
            Health Advisor
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

export default ImpersonateMenu
