import React, { useState } from 'react'
import { useModal } from '@contexts/ModalProvider'
import {
  Button,
  Flex,
  ModalFooter,
  useToast,
  Text,
  Stack,
  Input,
  Textarea,
  Box,
  Tag
} from '@chakra-ui/react'
import { Source, useAxios } from '@contexts/AxiosProvider'
import Select, { SingleValue } from 'react-select'
import { Package } from 'types/index'
import useSWR from 'swr'
import { collectionTypeIDToNameMap } from '@utils/collectionTypeNameMap'
import { useAuth } from '@contexts/AuthProvider'

const orderDuration = ['in 1 month', 'in 3 months', 'in 6 months', 'custom']

interface LabelValue {
  label: string
  value: string
}

interface LabelledOptions {
  label: string
  options: LabelValue[]
}

export interface OrderModalProps {
  patientID: string | undefined
}

interface OptionType {
  value: string
  label: string
}

const OrderModal: React.FC<OrderModalProps> = ({ patientID }) => {
  const [reasonText, setReasonText] = useState('')
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedPackage, setSelectedPackage] = useState<OptionType | null>(null)
  const { user } = useAuth()
  const shouldFetch = user && user.uid

  const toast = useToast()
  const { axiosInstanceFhir } = useAxios()
  const { setLoading, closeModal } = useModal()

  const { data: practitionerRecord } = useSWR(
    shouldFetch && [`/Practitioner?identifier=${user.uid}`, Source.FHIR]
  )

  const practitionerId = practitionerRecord?.entry?.[0]?.resource?.id

  const handleSelectedPeriod = (selectedPeriodVal: string) => {
    setSelectedPeriod((prevSelectedPeriod) =>
      prevSelectedPeriod === selectedPeriodVal ? null : selectedPeriodVal
    )
    setSelectedDate(null)
    const currentDate = new Date()
    switch (selectedPeriodVal) {
      case orderDuration[0]:
        currentDate.setDate(currentDate.getDate() + 30)
        setSelectedDate(currentDate)
        break

      case orderDuration[1]:
        currentDate.setDate(currentDate.getDate() + 90)
        setSelectedDate(currentDate)
        break

      case orderDuration[2]:
        currentDate.setDate(currentDate.getDate() + 180)
        setSelectedDate(currentDate)
        break

      default:
        break
    }
  }

  const { data: packages } = useSWR([`/packages?take=500&skip=0&active=true`, Source.CONFIG])
  const { data: patientToOffering } = useSWR([`/patient-to-offering/${patientID}`, Source.CONFIG])

  const offeringPackageIDs: string[] = []

  if (patientToOffering && patientToOffering.offering) {
    const { packageIDs } = patientToOffering.offering

    offeringPackageIDs.push(...(packageIDs as string[]))
  }

  const offeringPackageOptions: LabelValue[] = []
  const allPackageOptions: LabelValue[] = []

  packages?.data.forEach((item: Package) => {
    if (offeringPackageIDs.includes(item.id)) {
      offeringPackageOptions.push({
        value: item.id,
        label: `${item.nickName} | ${item.name} | ${collectionTypeIDToNameMap.get(
          item.collectionType
        )}`
      })
    } else {
      allPackageOptions.push({
        value: item.id,
        label: `${item.nickName} | ${item.name} | ${collectionTypeIDToNameMap.get(
          item.collectionType
        )}`
      })
    }
  })

  const packageOptions: LabelledOptions[] = [
    {
      label: 'All Active Packages',
      options: allPackageOptions
    }
  ]

  if (offeringPackageOptions.length > 0) {
    packageOptions.unshift({ label: 'Offering Packages', options: offeringPackageOptions })
  }

  const handleOption = (selectedOption: SingleValue<OptionType>) => {
    setSelectedPackage(selectedOption)
  }

  const showErrorToast = (title: string, description?: string) => {
    toast({
      title,
      description,
      status: 'error',
      duration: 4000,
      isClosable: true
    })
  }

  const handleAction = async () => {
    setLoading(true)
    if (!practitionerId) {
      showErrorToast('Error placing order', 'You are not authorized to place order')
      return
    }
    const body = {
      packageID: selectedPackage?.value,
      patientID: patientID,
      note: reasonText,
      authorID: practitionerId,
      occurrenceDate: selectedDate
    }

    try {
      await axiosInstanceFhir.post('/ServiceRequest/assign-custom-package', body)
      toast({
        title: 'Order placed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
      closeModal()
    } catch (error) {
      console.error('components/OrderModal/OrderModal.tsx#handleOrder', error)
      showErrorToast('Error placing order')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Flex gap={2} h="100%" flexDirection="column">
        <Stack>
          <Text>Test Package</Text>
        </Stack>
        <Stack>
          <Select
            value={selectedPackage}
            onChange={handleOption}
            options={packageOptions}
            placeholder="Search for a Package"
            isSearchable
          />
        </Stack>
        <Stack>
          <Text>Reason</Text>
        </Stack>
        <Stack>
          <Textarea
            value={reasonText}
            onChange={(e) => setReasonText(e.target.value)}
            placeholder="The reason for request"
            size="md"
            bg="quredGray.600"
          />
        </Stack>
        <Stack>
          <Text fontWeight="bold" fontSize="lg">
            Selected Period
          </Text>
        </Stack>
        <Stack direction="row" align="center">
          <Stack direction="row" align="center">
            {orderDuration.map((selectedPeriodVal) => (
              <Box key={selectedPeriodVal} onClick={() => handleSelectedPeriod(selectedPeriodVal)}>
                <Tag
                  size="lg"
                  borderRadius="full"
                  variant={selectedPeriod === selectedPeriodVal ? 'solid' : 'outline'}
                  borderColor="blue"
                  cursor="pointer"
                >
                  {selectedPeriodVal}
                </Tag>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack>
          {selectedPeriod === orderDuration[3] ? (
            <Box>
              <Text>Select Date</Text>
              <Input
                type="date"
                bg="quredGray.600"
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
              />
            </Box>
          ) : null}
        </Stack>
      </Flex>
      <ModalFooter gap={2} mt={4}>
        <Button onClick={handleAction}>{'Request'}</Button>
      </ModalFooter>
    </>
  )
}

export default OrderModal
