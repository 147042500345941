import { Flex, Text } from '@chakra-ui/react'
import { CommonIcon } from '@components/Icon/CommonIcon'

const ItemIcon = ({
  icon,
  value,
  size,
  color
}: {
  icon: string
  value: string
  color: string
  size: number
}) => {
  return (
    <Flex gap={2} alignItems="center">
      <Text fontWeight="bold" color="blackAlpha">
        {value}
      </Text>
      <CommonIcon name={icon} size={size} color={color} />
    </Flex>
  )
}

export default ItemIcon
