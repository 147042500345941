import { ThemeTypings } from '@chakra-ui/styled-system'

export interface Action {
  label: string
  onClick: () => void
  disabled?: boolean
  colorScheme?: ThemeTypings['colorSchemes']
}

export const enum Category {
  KitQrCode = 'KitQrCode',
  ResultsReporting = 'ResultsReporting',
  ReferralLetter = 'ReferralLetter'
}
