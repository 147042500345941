// 1. Import the extendTheme function
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  quredGray: {
    100: '#F9F9F9',
    200: '#D3D3D3',
    300: '#8C8E93',
    400: '#A9A9A9',
    450: '#4E5861',
    500: '#D9E2EA',
    600: '#F3F3F3',
    700: '#474747',
    800: '#262626',
    900: '#33404A'
  },

  quredBlue: {
    50: '#dce8ff',
    100: '#b3d2ff',
    200: '#8ab8ff',
    300: '#5f9eff',
    400: '#3f86ff',
    500: '#0021FF',
    600: '#0019e6',
    700: '#001ec1',
    800: '#0022a3',
    900: '#00247d'
  },
  quredPurple: {
    50: '#e2dff7',
    100: '#c3c3f0',
    200: '#a1a7e8',
    300: '#818ce0',
    400: '#6371d8',
    500: '#321D7A',
    600: '#2f1b73',
    700: '#2b1869',
    800: '#27155f',
    900: '#221155'
  },
  quredOrange: {
    50: '#fce8e6',
    100: '#f9cccc',
    200: '#f59090',
    300: '#f16c66',
    400: '#ed4c3f',
    500: '#DB4B19',
    600: '#d13e15',
    700: '#b73211',
    800: '#9b2709',
    900: '#7f1c00'
  },
  quredGreen: {
    50: '#e1f3f3',
    100: '#b3e5e5',
    200: '#84d8d8',
    300: '#55caca',
    400: '#26bebe',
    500: '#45CF79',
    600: '#3cbd7d',
    700: '#2fa97f',
    800: '#269282',
    900: '#1c7b84'
  },
  quredLilac: {
    50: '#f3e9f6',
    100: '#e5c4e9',
    200: '#d8a0dc',
    300: '#ca7dcf',
    400: '#be59c3',
    500: '#9373BF',
    600: '#906cb7',
    700: '#8d65af',
    800: '#8a5fa7',
    900: '#87599e'
  },
  quredPink: {
    0: '#fbe8ee',
    100: '#f7c8d7',
    200: '#f3a9c0',
    300: '#ef89b9',
    400: '#eb6ab2',
    500: '#F3C1CB',
    600: '#e79cb3',
    700: '#dc78a9',
    800: '#d155a0',
    900: '#c73197'
  },
  quredGold: {
    50: '#fbf5e7',
    100: '#f9e9c9',
    200: '#f7ddab',
    300: '#f5d18c',
    400: '#f3c56e',
    500: '#F2A517',
    600: '#e99b14',
    700: '#df9112',
    800: '#d58510',
    900: '#cb7900'
  }
}

const fonts = {
  heading: `'Inter', sans-serif`,
  body: `'Inter', sans-serif`
}

// 2. Update the breakpoints as key-value pairs
export const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px'
}

const components = {
  Button: {
    variants: {
      secondary: () => ({
        colorScheme: 'quredGray',
        bgColor: 'quredGray.100',
        border: '1px solid',
        color: 'quredGray.300',
        borderColor: 'quredGray.500'
      }),
      outline: () => ({
        backgroundColor: 'white',
        color: 'quredBlue.500'
      })
    }
  }
}

export const theme = extendTheme(
  { components, colors, breakpoints, fonts },
  withDefaultColorScheme({ colorScheme: 'quredBlue' })
)
