import { Role, RoleType } from 'types/role'

export const isGPRole = ({ role }: { role: Role }): boolean => {
  return role.code === RoleType.GPPractitioner
}

export const isHARole = ({ role }: { role: Role }): boolean => {
  return role.code === RoleType.HealthAdvisor
}

export const isSuperRole = ({ role }: { role: Role }): boolean => {
  return role.code === RoleType.Super
}

export const isSuperAdminRole = ({ role }: { role: Role }): boolean => {
  return role.code === RoleType.SuperAdmin
}

export const isResourceManagerRole = ({ role }: { role: Role }): boolean => {
  return role.code === RoleType.ResourceManager
}


