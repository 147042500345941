import { BundleLink } from 'fhir/r4'

export const extractTokenFromLinkUrl = (link: BundleLink[], linkRelationType: string) => {
  const urlValue = link.find((link: BundleLink) => link.relation === linkRelationType)?.url
  if (urlValue) {
    const url = new URL(urlValue)
    const token = url.searchParams.get('_page_token')
    const encodedPageToken = encodeURIComponent(token || '')
    return encodedPageToken
  }
  return ''
}

export const copyToClipboard = (text: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.position = 'fixed'
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.opacity = '0'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}
const quredEmailRegex = new RegExp(/^\S+@qured\.com$/)

export const isQuredEmail = ({ email }: { email: string }) => quredEmailRegex.test(email)
