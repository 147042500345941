export const NEXT_PUBLIC_FHIR_WRAPPER_URL = process.env.NEXT_PUBLIC_FHIR_WRAPPER_URL
export const NEXT_PUBLIC_ACCOUNT_SERVICE_URL = process.env.NEXT_PUBLIC_ACCOUNT_SERVICE_URL
export const NEXT_PUBLIC_CONFIG_SERVICE_URL = process.env.NEXT_PUBLIC_CONFIG_SERVICE_URL
export const timelineStep = 20 // minutes
export const isDevelopment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
export const isLocal = process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
export const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
export const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
export const telemedicineUrl = process.env.NEXT_PUBLIC_TELEMEDICINE_URL
export const dataStudioUrl = process.env.NEXT_PUBLIC_DATA_STUDIO_DASHBOARD_URL
export const NEXT_PUBLIC_ORDER_SERVICE_URL = process.env.NEXT_PUBLIC_ORDER_SERVICE_URL
export const NEXT_PUBLIC_APPOINTMENT_SERVICE_URL = process.env.NEXT_PUBLIC_APPOINTMENT_SERVICE_URL
export const NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY = process.env.NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY
