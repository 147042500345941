import React, { FC, ReactNode } from 'react'
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'

type Use = 'secondary' | 'secondaryOrange'

interface Props extends ButtonProps {
  children: ReactNode
  use: Use
}

const getStyles = (use: Use) => {
  if (use === 'secondary') {
    return {
      colorScheme: 'quredGray',
      bgColor: 'quredGray.100',
      color: 'quredGray.300',
      border: '1px solid',
      borderColor: 'quredGray.500'
    }
  }
  if (use === 'secondaryOrange') {
    return {
      colorScheme: 'customOrange',
      color: 'black'
    }
  }
}

export const CustomButton: FC<Props> = ({ children, use, ...props }) => {
  const styles = getStyles(use)

  const { bgColor, colorScheme, color, border, borderColor } = styles as ButtonProps

  return (
    <ChakraButton
      backgroundColor={bgColor}
      colorScheme={colorScheme}
      color={color}
      border={border}
      borderColor={borderColor}
      {...props}
    >
      {children}
    </ChakraButton>
  )
}

export default CustomButton
